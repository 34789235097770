import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"

import toppic04 from "/src/images/top-pic-04.png"

const Page = ({data}) => {
  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
  ]

  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 

  return(
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title={t("地域での活動")} lang={lang}/>
      <ContentLayout lang={lang}>
        <h1>{t("地域での活動")}</h1>
        <ul className="anchorlink">
          <li><AnchorLink anchor="#anchor01" >{t("連携大学院")}</AnchorLink></li>
          <li><AnchorLink anchor="#anchor02" >{t("つくばライフサイエンス推進協議会")}</AnchorLink></li>
          <li><AnchorLink anchor="#anchor03" >{t("筑波研究学園都市交流協議会")}</AnchorLink></li>
          <li><AnchorLink anchor="#anchor04" >{t("筑波会議")}</AnchorLink></li>
        </ul>
        <img src={ toppic04 } alt=""  className="img-wide"/>
        <h2 id="anchor01">{t("連携大学院")}</h2>
        <p>
          {t("理化学研究所では、生命科学研究の将来を担う若手研究者の育成を目的として、筑波大学と連携大学院協定を結び、所属研究者が客員教員として学生の研究指導を行っています。")}
        </p>
        <p className="txt-center">
          <External 
            href={"https://"+t("www.riken.jp/collab/partnerships/")}
            btn="2"
            >
            {t("国内外の大学・研究機関との協力")}
                        ({t("理化学研究所")})
          </External>
        </p>


        <h2 id="anchor02">{t("つくばライフサイエンス推進協議会")}</h2>
        <p>
          {t("この協議会は、大学、試験研究機関、産業、行政の連携・交流の促進を図るとともに、教育研究とその実用化を支援することにより、つくば地区のライフサイエンス分野における優れた特性をさらに伸ばし、教育研究・開発と産業の活性化を通じて地域および社会に貢献することを目的としています。バイオリソース研究センターはこの協議会に参画し、幹事を務めています。また、筑波大学に協働大学院ライフイノベーション学位プログラムを設置しており、当センターからも研究者が客員教授として委嘱を受け、バイオリソース概論などの講義を行いバイオリソース研究センターの重要性を伝えています。")}
        </p>
        <p className="txt-center">
          <External 
            href={"https://"+t("tsukuba-gi.jp/description/council/")}
            btn="2"
            >
            {t("つくばライフサイエンス推進協議会(つくばグローバル・イノベーション推進機構)")}
          </External>
        </p>


        <h2 id="anchor03">{t("筑波研究学園都市交流協議会")}</h2>
        <p className="txt-center">
          <External 
            href={"https://"+t("www.tsukuba-network.jp/")}
            btn="2"
            >
            {t("筑波研究学園都市交流協議会")}
          </External>
        </p>


        <h2 id="anchor04">{t("筑波会議")}</h2>
        <p>
          {t("筑波会議は、筑波会議実行委員会が主催し、筑波研究学園都市に、世界から産官学の優秀な若手を中心とする人材を集め、「社会と科学技術」の諸課題について議論を深める機会を提供する目的で開催されています。バイオリソース研究センターはこの会議に参画しています。")}
        </p>
        <p className="txt-center">
          <External 
            href="https://tsukuba-conference.com/"
            btn="2"
            >
            {t("筑波会議")}
          </External>
        </p>
        
      </ContentLayout>
    </Layout>
  )
}
export default Page

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }
`